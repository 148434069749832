<template>
  <div class="text-center mb-0 ml-0" style="padding-bottom: 2px">
    <router-link
      to="/vcm/codecsttus"
      class="table mb-0 table-bordered vcm-table"
      tag="table"
    >
      <tr>
        <th
          rowspan="2"
          class="table-light p-0"
          style="padding: 5px !important; font-size: 1.5rem"
        >
          Codec
        </th>
        <th
          class="text-critical table-light"
          style="padding: 5px !important; font-size: 1.2rem"
        >
          Critical
        </th>
        <th
          class="text-major table-light"
          style="padding: 5px !important; font-size: 1.2rem"
        >
          Major
        </th>
        <th
          class="text-minor table-light"
          style="padding: 5px !important; font-size: 1.2rem"
        >
          Minor
        </th>
        <th
          class="text-warning table-light"
          style="padding: 5px !important; font-size: 1.2rem"
        >
          Warning
        </th>
      </tr>
      <tr>
        <td style="font-size: 1.5rem">{{ criticalCount }}</td>
        <td style="font-size: 1.5rem">{{ majorCount }}</td>
        <td style="font-size: 1.5rem">{{ minorCount }}</td>
        <td style="font-size: 1.5rem">{{ warningCount }}</td>
      </tr>
    </router-link>
  </div>
</template>
<style scoped>
.bg-minor {
  background-color: #ffd400;
}
.bg-war {
  background-color: #00967e;
}
.text-critical {
  color: #ea5455 !important;
}
.text-major {
  color: #ff9f43 !important;
}
.text-minor {
  color: #ffd500 !important;
}
.text-warning {
  color: #00967e !important;
}
</style>
<script>
// text-center bg-danger mb-0
import { BCard } from "bootstrap-vue";

// const qs = require('qs')

export default {
  components: {
    BCard,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    classObject: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      codecUrl: "api/codec/servers/",
      criticalList: [],
      majorList: [],
      minorList: [],
      warningList: [],
      criticalCount: 0,
      majorCount: 0,
      minorCount: 0,
      warningCount: 0,
      criticalCount2: 0,
      majorCount2: 0,
      minorCount2: 0,
      warningCount2: 0,
      CriticalArr: [],
      MajorArr2: [],
      MinorArr3: [],
      WarningArr4: [],
      criticalList2: [],
      majorList2: [],
      minorList2: [],
      warningList2: [],
    };
  },
  created() {
    // this.getCodec()
    this.wsConect();
  },
  watch: {},
  methods: {
    /**
     * Codec 웹소켓
     * @created 2021-04-23
     * @author 정한슬
     * @comment 웹소켓에서 수신될 때마다 해당 내용에 맞춰 setData 함수 호출 후 내용 변경
     */
    wsConect() {
      // this.$options.sockets = new WebSocket(this.wsurl.url)
      this.$options.sockets.onmessage = (evt) => {
        // if (JSON.parse(evt.data).data.diagnotics == 'fault') {
        //   this.setData(JSON.parse(evt.data).data)
        // }
        this.setData(JSON.parse(evt.data).data);
      };
    },
    /**
     * 현재 사용안함 코덱 데이터 이벤트 요약 현황 바인딩 함수 ( wsConect로 대체 )
     */
    getCodec(callback) {
      // console.log(1123);
      this.$nBack.post(this.codecUrl).then((response) => {
        if (response.data) {
          if (
            response.data.resultCode == "success" &&
            response.data.resultMessage == "성공"
          ) {
            var i = 0;
            for (let value of response.data.data) {
              if (value.faultSt) {
                if (value.faultSt.toLowerCase() == "critical") {
                  this.CriticalArr.push(value);
                } else if (value.faultSt.toLowerCase() == "major") {
                  this.MajorArr2.push(value);
                } else if (value.faultSt.toLowerCase() == "minor") {
                  this.MinorArr3.push(value);
                } else if (value.faultSt.toLowerCase() == "warning") {
                  this.WarningArr4.push(value);
                }
              }
              i++;
              if (i === response.data.data.length) {
                this.criticalCount = this.CriticalArr.length;
                this.majorCount = this.MajorArr2.length;
                this.minorCount = this.MinorArr3.length;
                this.warningCount = this.WarningArr4.length;

                this.criticalCount2 = this.criticalCount;
                this.majorCount2 = this.majorCount;
                this.minorCount2 = this.minorCount;
                this.warningCount2 = this.warningCount;

                this.wsConect();
              }
            }
          }
        }
      });
    },
    /**
     * 코덱 요약현황 바인딩
     * @created 2021-04-26
     * @author 정한슬
     * @param {Array} item 이벤트 로그 현황
     * @comment 이벤트 로그 현황이 변할 때마다 해당 내용 변경 후 바인딩
     */
    setData(item) {
      // console.log(item)
      if (item) {
        if (item.payload) {
          this.criticalCount = item.payload.fault.items.critical.cnt
            ? item.payload.fault.items.critical.cnt
            : 0;
          this.majorCount = item.payload.fault.items.major.cnt
            ? item.payload.fault.items.major.cnt
            : 0;
          this.minorCount = item.payload.fault.items.minor.cnt
            ? item.payload.fault.items.minor.cnt
            : 0;
          this.warningCount = item.payload.fault.items.warning.cnt
            ? item.payload.fault.items.warning.cnt
            : 0;
        }
      }
    },
    /**
     * 단일 중복체크
     * @created 2021-04-23
     * @author 박재헌
     * @param {Array} array 중복제거할 배열
     * @param {String} key 중복제거할 값
     * @comment 키값 하나 입력 후 중복체크하여 중복되는 데이터 값 제거
     */
    getUniqueObjectArray(array, key) {
      return array.filter((item, i) => {
        return (
          array.findIndex((item2, k) => {
            return item[key] === item2[key];
          }) === i
        );
      });
    },
  },
};
</script>
