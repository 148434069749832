<template>
  <div class=" text-center mb-0 ml-0 pb-0">
    <table class="table mb-0 table-bordered vcm-table">
      <tr>
        <th rowspan="2" class="table-light p-0 text-nowrap" style="padding: 5px !important; font-size: 1.5rem;">UCS</th>
        <router-link :to="{ name: 'ucs-servobnt', query: { severity: 'critical' }}"  class="text-critical table-light" style="padding: 5px !important; font-size: 1.2rem;" tag="th">
          Critical
        </router-link>
        <router-link :to="{ name: 'ucs-servobnt', query: { severity: 'major' }}" class="text-major table-light" style="padding: 5px !important; font-size: 1.2rem;" tag="th">
          Major
        </router-link>
        <router-link :to="{ name: 'ucs-servobnt', query: { severity: 'minor' }}" class="text-minor table-light" style="padding: 5px !important; font-size: 1.2rem;" tag="th">
          Minor
        </router-link>
        <router-link :to="{ name: 'ucs-servobnt', query: { severity: 'warning' }}" class="text-warning table-light" style="padding: 5px !important; font-size: 1.2rem;" tag="th">
          Warning
        </router-link>
      </tr>
      <tr>
        <router-link :to="{ name: 'ucs-servobnt', query: { severity: 'critical' }}" style="font-size: 1.5rem;" tag="td">
          {{criticalCount}}
        </router-link>
        <router-link :to="{ name: 'ucs-servobnt', query: { severity: 'major' }}" style="font-size: 1.5rem;" tag="td">
          {{majorCount}}
        </router-link>
        <router-link :to="{ name: 'ucs-servobnt', query: { severity: 'minor' }}" style="font-size: 1.5rem;" tag="td">
          {{minorCount}}
        </router-link>
        <router-link :to="{ name: 'ucs-servobnt', query: { severity: 'warning' }}" style="font-size: 1.5rem;" tag="td">
          {{warningCount}}
        </router-link>
      </tr>
    </table>
  </div>
</template>
<style scoped>
  .bg-minor{
    background-color: #ffd400
  }
  .bg-war{
    background-color: #00967e
  }
  .text-critical{
  color: #ea5455!important;
  }
  .text-major{
    color: #ff9f43!important;
  }
  .text-minor{
    color: #ffd500!important;
  }
  .text-warning{
    color: #00967e!important;
  }
</style>
<script>
import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      criticalCount: 0,
      majorCount: 0,
      minorCount: 0,
      warningCount: 0,
      criticalList: [],
      majorList: [],
      minorList: [],
      warningList: [],
      items: {
        totals: 0,
        errTotals: 0,
        plainXml: [],
        critical: 0,
        major: 0,
        minor: 0,
        warning: 0
      }
    }
  },
  computed: {
    returnAllFaultList() {
      return this.setData(this.$store.state.ucsList.allFaultList)
    },
  },
  watch: {
    returnAllFaultList(val) {
      this.setData(val)
    },
  },
  created() {
    if (this.$store.state.ucsList.allFaultList.length < 0) {
      // console.log(this.$store.state.ucsList.allFaultList,'3333')
      this.setData(this.$store.state.ucsList.allFaultList)
    }
  },
  methods: {
    /**
     * UCS 장애 카운트 SET setData()
     * @create 2021-04-14
     * @author 정한슬
     * @param val
     * 첫 페이지 로딩 또는 store 데이터가 변경 될 시 ucs fault count set
     */
    setData(val) {
      this.items = val.cntList
      if(this.items){
        if(this.items.critical){
          this.criticalCount = this.items.critical
        }else{
          this.criticalCount = 0
        }
        if(this.items.major){
        this.majorCount = this.items.major
        }else{
          this.majorCount = 0
        }
        if(this.items.minor){
        this.minorCount = this.items.minor
        }else{
          this.minorCount = 0
        }
        if(this.items.warning){
        this.warningCount = this.items.warning
        }else{
          this.warningCount = 0
        }
      }
    },
  },
}
</script>
