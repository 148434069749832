<template>
  <layout-vertical
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
  >
    <router-view
      :meetingLists="this.meetingList"
      :callsLists="this.callsList"
      :reserveLists="this.reserveList"
      :recentLists="this.recentList"
      :slide="this.slides"
      :areaOption="this.areaOption"
      :cmsSystemLists="this.cmsSystemList"
      :cmsLoading="this.cmsLoading"
      :totCalls="this.totCalls"
      :ucsLoading="this.ucsLoading"
    />

    <!-- header vertical 상단 -->
    <div
      slot="vertical-menu-header"
      :collapseTogglerIcon="collapseTogglerIcon"
      :is-vertical-menu-active="isVerticalMenuActive"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
    >
      <ul class="nav navbar-nav flex-row">
        <!-- Logo & Text -->
        <li class="nav-item mr-auto">
          <b-link class="navbar-brand" to="/">
            <span class="logo-text" style="font-size: 24px; padding-top: 22px">
              Knox Connect
            </span>
          </b-link>
        </li>
        <!-- Toggler Button -->
        <li class="nav-item nav-toggle">
          <b-link class="nav-link modern-nav-toggle">
            <feather-icon
              icon="XIcon"
              size="20"
              class="d-block d-xl-none text-secondary"
              @click="toggleVerticalMenuActive"
            />
            <feather-icon
              :icon="collapseTogglerIconFeather"
              size="20"
              class="d-none d-xl-block collapse-toggle-icon text-secondary"
              @click="toggleCollapsed"
            />
          </b-link>
        </li>
      </ul>
    </div>
    <!-- header 상단 navigation  -->
    <div
      slot="navbar"
      class="navbar-container d-flex content align-items-center p-5px"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
    >
      <!-- Nav Menu Toggler -->
      <ul class="nav navbar-nav d-xl-none mr-1">
        <li class="nav-item">
          <b-link class="nav-link" @click="toggleVerticalMenuActive">
            <feather-icon icon="MenuIcon" size="21" />
          </b-link>
        </li>
      </ul>

      <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
        <b-row class="w-100 ml-0">
          <b-col cols="12" lg="12" class="mb-1">
            <b-navbar-nav class="nav align-items-center mr-auto justify-center">
              <router-link
                tag="h3"
                to="/dashboard/main"
                class="title-clock font-weight-bolder mb-0 menu-custom"
                style="padding: 0 0.5rem"
                >{{ clock }}</router-link
              >
              <dark-Toggler class="navbasic-text custom float-right" />
              <user-dropdown class="navbasic-text float-right" />
            </b-navbar-nav>
          </b-col>
          <b-col lg="4">
            <cms-nav-sumry
              :slide="this.slides"
              :areaOption="this.areaOption"
              :meetingLists="$store.state.cmsList.meetingList"
              :callsLists="$store.state.cmsList.callsList"
              :reserveLists="$store.state.cmsList.reserveList"
            ></cms-nav-sumry>
          </b-col>
          <b-col lg="4">
            <ucs-nav-card
              :criticalCount="ucsCritical"
              :majorCount="ucsMajor"
              :minorCount="ucsMinor"
              :warningCount="ucsWarning"
            ></ucs-nav-card>
          </b-col>
          <b-col lg="4">
            <vcm-nav-card
              :criticalCount="vcmCritical"
              :majorCount="vcmMajor"
              :minorCount="vcmMinor"
              :warningCount="vcmWarning"
            ></vcm-nav-card>
          </b-col>
        </b-row>
      </div>
    </div>
    <!-- header 상단 navigation END -->
    <div slot="footer"></div>
  </layout-vertical>
</template>

<script>
import { BLink, BNavbarNav, BTooltip, BRow, BCol } from "bootstrap-vue";

import { onUnmounted } from "@vue/composition-api";
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
import UserDropdown from "@core/layouts/components/app-navbar/components/UserDropdown.vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { computed } from "@vue/composition-api";

import useAppConfig from "@core/app-config/useAppConfig";
import useVerticalNavMenu from "@core/layouts/layout-vertical/components/vertical-nav-menu/useVerticalNavMenu";
import useVerticalLayout from "@core/layouts/layout-vertical/useVerticalLayout";

import UcsNavCard from "../../views/ucs/components/UcsNavCard.vue";
import VcmNavCard from "../../views/vcm/components/VcmNavCard.vue";
import CmsNavSumry from "../../views/cms/components/CmsNavSumry.vue";

const qs = require("qs");

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BNavbarNav,
    BTooltip,
    LayoutVertical,
    DarkToggler,
    UcsNavCard,
    VcmNavCard,
    CmsNavSumry,
    UserDropdown,
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer;
      if (rendererType === "sidebar-left")
        return "layout-content-renderer-left";
      if (rendererType === "sidebar-left-detached")
        return "layout-content-renderer-left-detached";
      return "layout-content-renderer-default";
    },
  },
  setup(props) {
    const {
      routerTransition,
      navbarBackgroundColor,
      navbarType,
      footerType,
      isNavMenuHidden,
    } = useAppConfig();

    const { collapseTogglerIcon, toggleCollapsed } = useVerticalNavMenu(props);
    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType);

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon"
    );

    // Resize handler
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    onUnmounted(() => {
      window.removeEventListener("resize", resizeHandler);
    });

    return {
      routerTransition,
      navbarBackgroundColor,
      isNavMenuHidden,
      collapseTogglerIconFeather,
      collapseTogglerIcon,
      toggleCollapsed,
      isVerticalMenuCollapsed,
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    };
  },
  data() {
    return {
      meetingUrl: "/",
      callsUrl: "/",
      reserveUrl: "/",
      recentUrl: "/",
      systemUrl: "/",
      ucsUrl: "nuova",
      meetingList: {},
      callsList: {},
      recentList: {},
      reserveList: {},
      mainServOb: [],
      cmsSystemList: {},
      limitCalls: "0",
      totCalls: "0",
      totCospaces: "0",
      todayResv: "0",
      totResv: "0",
      ucsCritical: 0,
      ucsMajor: 0,
      ucsMinor: 0,
      ucsWarning: 0,
      vcmCritical: 0,
      vcmMajor: 0,
      vcmMinor: 0,
      vcmWarning: 0,
      changcomp: 1,
      mainServObList: [],
      clock: "",
      areaOption: [],
      slides: 0,
      cmsLoading: true,
      ucsLoading: false,
    };
  },
  created() {
    this.ucsWsConect(); // UCS 웹소켓
    this.cmsWsConect(); // CMS 웹소켓
    this.getVcmNav();
  },
  mounted() {
    this.cmsServerSetting();
    setInterval(() => {
      this.getCmsSetSliding();
      this.cmsServerSetting();
    }, this.interverTime());
    setInterval(this.getTime, 1000);
  },
  watch: {},
  methods: {
    interverTime() {
      let sT = this.areaOption.length * 4000;
      let time = sT + 10000;
      return time;
    },
    /**
     * CMS 슬라이드 숫자 셋팅
     * @created 2021-04-23
     * @author 박재헌
     * @comment 서버 갯수가 1개보다 많을 시 슬라이딩 되도록, 미만일 시 슬라이드되지 않도록 세팅
     */
    getCmsSetSliding() {
      let slideChk = 0;
      if (this.areaOption.length < 1) {
        slideChk = this.areaOption.length;
      } else {
        slideChk = this.areaOption.length - 1;
      }
      if (this.slides < slideChk) {
        this.slides++;
      } else {
        this.slides = 0;
      }
      // console.log(this.slides, this.areaOption.length)
    },
    /**
     * VCM 헤더 초기 세팅
     * @created 2021-03-21
     * @author 박재헌
     * @comment 현재 사용 안함 VCM 헤더 초기 0 세팅
     */
    getVcmNav() {
      this.vcmCritical = 0;
      this.vcmMajor = 0;
      this.vcmMinor = 0;
      this.vcmWarning = 0;
    },
    /**
     * 실시간 시간 변경
     * @created 2021-04-26
     * @author 박재헌
     * @comment 실시간으로 변하는 시간
     */
    getTime() {
      const time = new Date();
      const year = time.getFullYear();
      const month = time.getMonth() + 1;
      const day = time.getDate();
      const hour = time.getHours();
      const minutes = time.getMinutes();
      const seconds = time.getSeconds();
      // clock.innerHTML = year + ' ' + hour +":" + minutes + ":"+seconds;
      this.clock = `${year < 10 ? `0${year}` : year}-${
        month < 10 ? `0${month}` : month
      }-${day < 10 ? `0${day}` : day} ${hour < 10 ? `0${hour}` : hour}:${
        minutes < 10 ? `0${minutes}` : minutes
      }:${seconds < 10 ? `0${seconds}` : seconds}`;
      // this.clock = `${month<10 ? `0${month}`:month}-${day<10 ? `0${day}`:day} ${hour<10 ? `0${hour}`:hour}:${minutes<10 ? `0${minutes}`:minutes}:${seconds<10 ? `0${seconds}`:seconds}`
    },
    /**
     * UCS WEB SOCKET
     * @create 2021-04-05
     * @author 정한슬
     * @returns {json}
     * @description
     */
    ucsWsConect() {
      const _self = this;
      // this.ucssocket = new WebSocket(`${this.currwss}//localhost:1330/ucsapi`)
      this.ucssocket = new WebSocket(`${this.ucssocket.url}`);
      // webSocket open
      this.ucssocket.onopen = function (e) {
        // console.log("[open] 커넥션이 만들어졌습니다.");
      };
      // webSocket get message
      this.ucssocket.onmessage = (evt) => {
        // console.log(JSON.parse(evt.data));
        this.ucsLoading = true; // 스켈레톤 display block
        // api == 1 - 전체 서버 장애 현황 조회
        // api == 2 - 전체 이벤트 로깅
        if (JSON.parse(evt.data).api == "2") {
          this.$store.commit(
            "chngUcsFaultList",
            JSON.parse(evt.data).returns.data
          );
          // if (JSON.parse(evt.data).returns.data.length > 0) {
          //   for (let i = 0; JSON.parse(evt.data).returns.data.length > i; i++){
          //     let dataF = JSON.parse(evt.data).returns.data[i]
          //     // data["currTm"] = new Date().format("yyyy-MM-dd HH:mm:ss")
          //     this.$store.commit('updateUcsFaultList', dataF)
          //     // console.log(dataF)
          //     if(i == (JSON.parse(evt.data).returns.data.length - 1)){ // --- sn, ip, severity, created 중복제거
          //       let uniqFList = this.getUniqueObjectArray2(this.$store.state.ucsList.allFaultList, 'id', 'sn', 'severity', 'lastTransition')
          //       this.$store.commit('chngUcsFaultList', uniqFList)
          //     }
          //   }
          // }
        } else if (JSON.parse(evt.data).api == "1") {
          // [hdh] 1시간 이전 데이터는 삭제
          // console.log('event Log Suceeus')
          // this.$store.commit('chngUcsEvtList', this.ucsWsDateSplice(this.$store.state.ucsList.allEventList))

          // if (JSON.parse(evt.data).returns.data.length > 0) {
          // for (let c = 0; JSON.parse(evt.data).returns.data.length > c; c++){
          let dataE = JSON.parse(evt.data).returns.data;
          // data["currTm"] = new Date().format("yyyy-MM-dd HH:mm:ss")
          this.$store.commit("updateUcsEvtList", dataE);
          // console.log(111,JSON.parse(evt.data).returns.data.length - 1)
          // if(c == (JSON.parse(evt.data).returns.data.length - 1)){ // --- sn, ip, severity, created 중복제거
          //   let uniqEList = this.getUniqueObjectArray2(this.$store.state.ucsList.allEventList, 'id', 'sn', 'severity', 'created')
          //   // let uniqList = this.$store.state.ucsList.allEventList
          //   this.$store.commit('chngUcsEvtList', uniqEList)
          //   // console.log(222,uniqList)
          // }
          // }
          // }
        }
        this.ucsLoading = false; // 스켈레톤 display none
      };
      // webSocket close 됐을 때 reconnect
      this.ucssocket.onclose = function (event) {
        // console.log('close :: ',event)
        setTimeout(function () {
          _self.ucsWsConect();
        }, 1000);
        // console.log("클로즈 됨", event)
      };
      // webSocket error 시 close
      this.ucssocket.onerror = function (err) {
        // console.error('Socket encountered error: ', err.message, 'Closing socket');
        this.ucssocket.close();
      };
    },
    /**
     * CMS WEB SOCKET
     * @create 2021-04-05
     * @author 정한슬
     * @returns {json}
     * @description
     */
    cmsWsConect() {
      this.cmsLoading = true;
      const _self = this;

      this.cmssocket = new WebSocket(`${this.cmssocket.url}`);

      this.cmssocket.onopen = function (e) {
        // this.cmsLoading = false;
      };
      this.cmssocket.onmessage = (evt) => {
        /**
         * api == meeting - 미팅룸 조회
         * api == calls - 현재 진행중인 미팅 정보
         * api == reserve - 예약 목록
         * api == system - 전체 이벤트 로깅
         * api == recent - 현재 CMS 정보
         */
        if (JSON.parse(evt.data).api == "meeting") {
          // console.log('meeting ******** ', JSON.parse(evt.data).returns.data)
          this.$store.commit(
            "updateMeetingList",
            JSON.parse(evt.data).returns.data
          );
        } else if (JSON.parse(evt.data).api == "calls") {
          this.$store.commit(
            "updateCallsList",
            JSON.parse(evt.data).returns.data
          );
        } else if (JSON.parse(evt.data).api == "reserve") {
          this.$store.commit(
            "updateReserveList",
            JSON.parse(evt.data).returns.data
          );
        } else if (JSON.parse(evt.data).api == "system") {
          this.$store.commit(
            "updateSystemList",
            JSON.parse(evt.data).returns.data
          );
        } else if (JSON.parse(evt.data).api == "recent") {
          this.$store.commit(
            "updateRecentList",
            JSON.parse(evt.data).returns.data
          );
        }

        // CMS SKELETON LOADING
        this.cmsLoading = false;
        // console.log(this.cmsLoading, ":: T1");
        // console.log("스토어 저장", this.$store.state.cmsList)
      };

      this.cmssocket.onclose = function (event) {
        this.cmsLoading = true;
        setTimeout(function () {
          _self.cmsWsConect();
        }, 1000);
      };
      this.cmssocket.onerror = function (err) {
        this.cmsLoading = true;
        this.cmssocket.close();
      };
    },
    /**
     * CMS SERVER 세팅
     * @created 2021-04-26
     * @author 박재헌
     * @comment CMS 서버를 areaOption 에 세팅 메인 및 헤더에 슬라이드 기능에 활용
     */
    cmsServerSetting() {
      // console.log('TTTT Server', this.$store.state.cmsServerList)
      const cmsServerList = this.$store.state.cmsServerList;
      this.areaOption = [];
      for (let index = 0; index < cmsServerList.length; index++) {
        const element = cmsServerList[index];
        this.areaOption.push(element);
      }
    },
    /**
     * 단일 중복체크
     * @created 2021-04-23
     * @author 박재헌
     * @param {Array} array 중복제거할 배열
     * @param {String} key 중복제거할 값
     * @comment 키값 하나 입력 후 중복체크하여 중복되는 데이터 값 제거
     */
    getUniqueObjectArray(array, key) {
      return array.filter((item, i) => {
        return (
          array.findIndex((item2, k) => {
            return item[key] === item2[key];
          }) === i
        );
      });
    },
    /**
     * 다중 중복체크
     * @created 2021-04-23
     * @author 박재헌
     * @param {Array} array 중복제거할 배열
     * @param {String} key 중복제거할 값
     * @param {String} key2 중복제거할 값
     * @param {String} key3 중복제거할 값
     * @param {String} key4 중복제거할 값
     * @comment 키값을 여러개 입력하여 해당 중복되는 값들 제거
     */
    getUniqueObjectArray2(array, key, key2, key3, key4) {
      return array.filter((item, i) => {
        return (
          array.findIndex((item2, k) => {
            return (
              item[key] === item2[key] &&
              item[key2] === item2[key2] &&
              item[key3] === item2[key3] &&
              item[key4] === item2[key4]
            );
          }) === i
        );
      });
    },
    /**
     * 이벤트 로그 1시간 이전 데이터 삭제
     * @created 2021-04-23
     * @author 정한슬
     */
    ucsWsDateSplice(val) {
      // [hdh] 1시간 이전 데이터는 삭제
      let returns = [];
      for (let i = val.length - 1; i >= 0; i--) {
        let tmDiff = new Date().getTime() - new Date(val[i].currTm).getTime();
        // console.log(111,Math.floor(tmDiff / 1000 / 60))
        // console.log(222,new Date().getTime())
        // console.log(333,val[i].currTm)
        if (Math.floor(tmDiff / 1000 / 60) > 60) {
          // 1시간 이전 데이터는 삭제
          val.splice(i, 1);
        }
        if (i === 0) returns = val;
      }
      return returns;
    },
  },
};
</script>

