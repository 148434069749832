<template>
  <div class="text-center mb-0">
    <b-carousel
      id="carousel-1"
      :interval="0"
      v-model="slide"
      v-if="this.areaOption.length != 0"
    >
      <b-carousel-slide v-for="(areaSet, idx) in areaOption" :key="idx">
        <template #img>
          <table class="table mb-0 table-bordered">
            <tr>
              <th
                rowspan="2"
                class="table-light p-0 text-nowrap"
                style="padding: 5px !important; font-size: 1.5rem"
              >
                CMS {{ areaSet.sn }}
              </th>
              <router-link
                :to="{ name: 'cms-metroom', query: { selectArea: areaSet.sn } }"
                tag="th"
                class="table-light text-dark cms-nav-th"
                >미팅</router-link
              >
              <router-link
                :to="{ name: 'cms-calls', query: { selectArea: areaSet.sn } }"
                tag="th"
                class="table-light text-dark cms-nav-th"
                >Calls</router-link
              >
              <router-link
                :to="{ name: 'cms-resv', query: { selectArea: areaSet.sn } }"
                tag="th"
                class="table-light text-dark cms-nav-th"
                >예약</router-link
              >
              <!-- <router-link :to="{name : 'cms-log-day', query : { selectArea : areaSet.sn } }" tag="th" class="table-light text-dark cms-nav-th">일별통계</router-link> -->
              <!-- <router-link :to="{name : 'cms-log-month', query : { selectArea : areaSet.sn } }" tag="th" class="table-light text-dark cms-nav-th">월별통계</router-link> -->
            </tr>
            <tr>
              <!-- {{areaSet}} -->
              <router-link
                v-if="meetingLists[`${areaSet.sn}`]"
                :to="{ name: 'cms-metroom', query: { selectArea: areaSet.sn } }"
                tag="td"
                class="table-white text-dark cms-nav-td"
                >{{
                  meetingLists[`${areaSet.sn}`].payload.totCospaces
                }}</router-link
              >
              <router-link
                v-else
                :to="{ name: 'cms-metroom', query: { selectArea: areaSet.sn } }"
                tag="td"
                class="table-white text-dark cms-nav-td"
                ><b-skeleton
                  type="avatar"
                  height="30px"
                  width="30px"
                  style="margin: 0 auto"
                ></b-skeleton
              ></router-link>
              <router-link
                v-if="callsLists[`${areaSet.sn}`]"
                :to="{ name: 'cms-calls', query: { selectArea: areaSet.sn } }"
                tag="td"
                class="table-white text-dark cms-nav-td"
                >{{ callsLists[`${areaSet.sn}`].payload.totCalls }}/{{
                  callsLists[`${areaSet.sn}`].payload.limitCalls
                }}</router-link
              >
              <router-link
                v-else
                :to="{ name: 'cms-calls', query: { selectArea: areaSet.sn } }"
                tag="td"
                class="table-white text-dark cms-nav-td"
                ><b-skeleton
                  type="avatar"
                  height="30px"
                  width="30px"
                  style="margin: 0 auto"
                ></b-skeleton
              ></router-link>
              <router-link
                v-if="reserveLists[`${areaSet.sn}`]"
                :to="{ name: 'cms-resv', query: { selectArea: areaSet.sn } }"
                tag="td"
                class="table-white text-dark cms-nav-td"
              >
                <span v-if="reserveLists[`${areaSet.sn}`].payload.todayResv">{{
                  reserveLists[`${areaSet.sn}`].payload.todayResv
                }}</span
                ><span v-else>0</span>/<span
                  v-if="reserveLists[`${areaSet.sn}`].payload.totResv"
                  >{{ reserveLists[`${areaSet.sn}`].payload.totResv }}</span
                ><span v-else>0</span>
              </router-link>
              <router-link
                v-else
                :to="{ name: 'cms-resv', query: { selectArea: areaSet.sn } }"
                tag="td"
                class="table-white text-dark cms-nav-td"
                ><b-skeleton
                  type="avatar"
                  height="30px"
                  width="30px"
                  style="margin: 0 auto"
                ></b-skeleton
              ></router-link>
              <!-- <router-link v-if="callsLists[`${areaSet.sn}`]" :to="{name : 'cms-log-day', query : { selectArea : areaSet.sn } }" tag="td" class="table-white text-dark cms-nav-td">{{ callsLists[`${areaSet.sn}`].payload.totCalls }}/{{ callsLists[`${areaSet.sn}`].payload.limitCalls }}</router-link> -->
              <!-- <router-link v-else :to="{name : 'cms-log-day', query : { selectArea : areaSet.sn } }" tag="td" class="table-white text-dark cms-nav-td"><b-skeleton type="avatar" height="30px" width="30px" style="margin: 0 auto"></b-skeleton></router-link> -->
              <!-- <router-link v-if="callsLists[`${areaSet.sn}`]" :to="{name : 'cms-log-month', query : { selectArea : areaSet.sn } }" tag="td" class="table-white text-dark cms-nav-td">{{ callsLists[`${areaSet.sn}`].payload.totCalls }}/{{ callsLists[`${areaSet.sn}`].payload.limitCalls }}</router-link> -->
              <!-- <router-link v-else :to="{name : 'cms-log-month', query : { selectArea : areaSet.sn } }" tag="td" class="table-white text-dark cms-nav-td"><b-skeleton type="avatar" height="30px" width="30px" style="margin: 0 auto"></b-skeleton></router-link> -->
            </tr>
          </table>
        </template>
      </b-carousel-slide>
    </b-carousel>
    <div v-else>
      <table class="table mb-0 table-bordered">
        <tr>
          <th
            rowspan="2"
            class="table-light p-0 text-nowrap"
            style="padding: 5px !important; font-size: 1.5rem"
          >
            CMS
          </th>
          <router-link
            :to="{ name: 'cms-metroom' }"
            tag="th"
            class="table-light text-dark cms-nav-th"
            >미팅</router-link
          >
          <router-link
            :to="{ name: 'cms-calls' }"
            tag="th"
            class="table-light text-dark cms-nav-th"
            >Calls</router-link
          >
          <router-link
            :to="{ name: 'cms-resv' }"
            tag="th"
            class="table-light text-dark cms-nav-th"
            >예약</router-link
          >
          <!-- <router-link :to="{name : 'cms-log-day'}" tag="th" class="table-light text-dark cms-nav-th">일별통계</router-link> -->
          <!-- <router-link :to="{name : 'cms-log-month'}" tag="th" class="table-light text-dark cms-nav-th">월별통계</router-link> -->
        </tr>
        <tr>
          <!-- {{areaSet}} -->
          <router-link
            :to="{ name: 'cms-metroom' }"
            tag="td"
            class="table-white text-dark cms-nav-td"
            ><b-skeleton
              type="avatar"
              height="30px"
              width="30px"
              style="margin: 0 auto"
            ></b-skeleton
          ></router-link>
          <router-link
            :to="{ name: 'cms-calls' }"
            tag="td"
            class="table-white text-dark cms-nav-td"
            ><b-skeleton
              type="avatar"
              height="30px"
              width="30px"
              style="margin: 0 auto"
            ></b-skeleton
          ></router-link>
          <router-link
            :to="{ name: 'cms-resv' }"
            tag="td"
            class="table-white text-dark cms-nav-td"
            ><b-skeleton
              type="avatar"
              height="30px"
              width="30px"
              style="margin: 0 auto"
            ></b-skeleton
          ></router-link>
          <!-- <router-link :to="{name : 'cms-log-day' }" tag="td" class="table-white text-dark cms-nav-td"><b-skeleton type="avatar" height="30px" width="30px" style="margin: 0 auto"></b-skeleton></router-link> -->
          <!-- <router-link :to="{name : 'cms-log-month' }" tag="td" class="table-white text-dark cms-nav-td"><b-skeleton type="avatar" height="30px" width="30px" style="margin: 0 auto"></b-skeleton></router-link> -->
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import {
  BNavItem,
  BNav,
  BCard,
  BCarousel,
  BCarouselSlide,
  BSkeleton,
} from "bootstrap-vue";

export default {
  components: {
    BNavItem,
    BNav,
    BCard,
    BCarousel,
    BCarouselSlide,
    BSkeleton,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    slide: {
      type: Number,
      default: 0,
    },
    areaOption: {
      type: Array,
      default: () => [],
    },
    meetingLists: {
      type: Object,
      default: () => {},
    },
    callsLists: {
      type: Object,
      default: () => {},
    },
    reserveLists: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sliding: null,
    };
  },
  mounted() {
    // console.log(this.areaOption.length)
  },
  computed: {},
  watch: {},
  methods: {
    /**
     * CMS 요약현황 Count
     * @created 2021-04-20
     * @author 박재헌
     * @param {Array} areaOption 서버리스트 sn 가져오기위한 서버리스트 배열
     * @param {Number} slide 서버리스트 sn 순으로 도는 슬라이드 숫자
     * @param {Array} $store.state.cmsList CMS 요약현황 리스트 배열
     * @comment 슬라이드도는 순번으로 서버리스트에 대조 후 해당 CMS 서버의 cmsList 각 요약현황 세팅
     */
    setCmsCount() {
      let cmsChk = this.areaOption[this.slide].sn;
      if (
        this.areaOption[this.slides].sn ===
        this.$store.state.cmsList.callsList[`${cmsChk}`].sn
      ) {
        this.limitCalls =
          this.$store.state.cmsList.callsList[`${cmsChk}`].limitCalls;
        this.totCalls =
          this.$store.state.cmsList.callsList[`${cmsChk}`].totCalls;
        this.totCospaces =
          this.$store.state.cmsList.meetingList[`${cmsChk}`].totCospaces;
        if (
          this.$store.state.cmsList.reserveList[`${cmsChk}`].todayResv === "" ||
          this.$store.state.cmsList.reserveList[`${cmsChk}`].todayResv ===
            undefined ||
          this.$store.state.cmsList.reserveList[`${cmsChk}`].todayResv === null
        ) {
          this.todayResv =
            this.$store.state.cmsList.reserveList[`${cmsChk}`].todayResv;
        } else {
          this.todayResv = 0;
        }
        if (
          this.$store.state.cmsList.reserveList[`${cmsChk}`].totResv === "" ||
          this.$store.state.cmsList.reserveList[`${cmsChk}`].totResv ===
            undefined ||
          this.$store.state.cmsList.reserveList[`${cmsChk}`].totResv === null
        ) {
          this.totResv =
            this.$store.state.cmsList.reserveList[`${cmsChk}`].totResv;
        } else {
          this.totResv = 0;
        }
        // console.log(this.$store.state.cmsList.reserveList[`${cmsChk}`].todayResv)
        // this.recentList = this.$store.state.cmsList.recentList[`${cmsChk}`].list
      } else {
        this.limitCalls = 0;
        this.totCalls = 0;
        this.totCospaces = 0;
        this.todayResv = 0;
        this.totResv = 0;
      }
    },
  },
  created() {},
};
</script>
